import styled from "styled-components";

export const Featured = styled.div`
    width:100%;
    display:flex;
    justify-content:space-between;
`;

export const FeaturedItem = styled.div`
    flex:1;
    margin:0px 20px;
    padding:30px;
    border-radius:10px;
    cursor:pointer;
    -webkit-box-shadow: 5px 5px 15px 5px #000000; 
    box-shadow: 5px 5px 15px 5px #000000;

    @media screen and (max-width: 640px){
        flex:3;
        margin:0px 0px;
        padding:0px;
    }

    @media screen and (max-width: 768px){
        flex:3;
        margin:0px 0px;
        padding:0px;
    }
`;

export const FeaturedTitle = styled.span`
    font-size:20px;
`;

export const FeaturedMoneyContainer = styled.div`
    margin:10px 0px;
    display:flex;
    align-items:center;
`;

export const FeaturedMoney = styled.span`
    font-weight:600;
`;

export const FeaturedMoneyRate = styled.span`
    display:flex;
    align-items:center;
    margin-left:20px;
`;

export const FeaturedSub = styled.span`
    font-size:15px;
    color:gray;
`;