import styled from 'styled-components';

export const WidgetLgContainer = styled.div`
    flex:2;
    -webkit-box-shadow: 5px 5px 15px 5px #000000; 
    box-shadow: 5px 5px 15px 5px #000000;
    padding:20px;
`;

export const WidgetLgTitle = styled.h3`
    font-size:20px;
    font-weight:600;
`;

export const WidgetLgImage = styled.img`
    width:40px;
    height:40px;
    border-radius:50%;
    object-fit:cover;
    margin-right:10px;
`;

export const WidgetLgTable = styled.table`
    width:100%;
    border-spacing:20px;
`;

export const WidgetLgTh = styled.th`
    text-align:left;
`;

export const WidgetLgUser = styled.td`
    display:flex;
    align-items:center;
    font-weight:600;
`;

export const WidgetLgDate = styled.td`
    font-weight:300;
`;

export const WidgetLgAmount = styled.td`
    font-weight:300;
`;