import React from 'react';
import FeaturedInfo from '../../components/featuredinfo/FeaturedInfo';
import Chart from '../../components/chart/Chart';
import { userData } from '../../dummyData';
import WidgetSm from '../../components/widgetsm/WidgetSm';
import WidgetLg from '../../components/widgetlg/WidgetLg';
//

import { 
  HomeContainer,
  HomeWidget, 
} from './HomeStyles';

export default function Home() {
  return (
    <HomeContainer className='home' id='home'>
      <FeaturedInfo/>
      <Chart data={userData} title="User Analytics" grid dataKey="Active User" />
      <HomeWidget>
        <WidgetSm />
        <WidgetLg />
      </HomeWidget>
    </HomeContainer>
  )
}
