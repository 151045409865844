import React from 'react';
import './App.css';
import './indexroute';

import Topbar from './components/topbar/Topbar';
import Sidebar from './components/sidebar/Sidebar';
import Home from './pages/home/Home';
import UserList from './pages/userlist/UserList';

function App() {
  return (
    <div>
      <Topbar />
      <div className='container'>
        <Sidebar />
        <Home />
        <UserList />
      </div>
    </div>
  );
}

export default App;
