import React from 'react';
import './widgetsm.css';
import pakistan from '../topbar/pictures/female_pakistan_200.png';
import { Visibility } from '@material-ui/icons';
import { 
  WidgetSmContainer,
  WidgetSmImage,
  WidgetSmTitle,
  WidgetSmList,
  WidgetSmListItem,
  WidgetSmUser,
  WidgetSmUsername,
  WidgetSmUserTitle,
  WidgetSmButton,
} from './WidgetSmStyles';

export default function WidgetSm() {
  return (
    <WidgetSmContainer id='widgetsm'>
      <WidgetSmTitle>New join Members</WidgetSmTitle>
      <WidgetSmList>
        <WidgetSmListItem>
          <WidgetSmImage src={pakistan} alt="avatar" />
          <WidgetSmUser>
            <WidgetSmUsername>Amina Yasmina</WidgetSmUsername>
            <WidgetSmUserTitle>Manager</WidgetSmUserTitle>
          </WidgetSmUser>
          <WidgetSmButton>
            <Visibility className="widgetSmIcon"/>
            Display
          </WidgetSmButton>
        </WidgetSmListItem>

        <WidgetSmListItem>
          <WidgetSmImage src={pakistan} alt="avatar" />
          <WidgetSmUser>
            <WidgetSmUsername>Amina Yasmina</WidgetSmUsername>
            <WidgetSmUserTitle>Manager</WidgetSmUserTitle>
          </WidgetSmUser>
          <WidgetSmButton>
            <Visibility className="widgetSmIcon"/>
            Display
          </WidgetSmButton>
        </WidgetSmListItem>

        <WidgetSmListItem>
          <WidgetSmImage src={pakistan} alt="avatar" />
          <WidgetSmUser>
            <WidgetSmUsername>Amina Yasmina</WidgetSmUsername>
            <WidgetSmUserTitle>Manager</WidgetSmUserTitle>
          </WidgetSmUser>
          <WidgetSmButton>
            <Visibility className="widgetSmIcon"/>
            Display
          </WidgetSmButton>
        </WidgetSmListItem>

        <WidgetSmListItem>
          <WidgetSmImage src={pakistan} alt="avatar" />
          <WidgetSmUser>
            <WidgetSmUsername>Amina Yasmina</WidgetSmUsername>
            <WidgetSmUserTitle>Manager</WidgetSmUserTitle>
          </WidgetSmUser>
          <WidgetSmButton>
            <Visibility className="widgetSmIcon"/>
            Display
          </WidgetSmButton>
        </WidgetSmListItem>

        <WidgetSmListItem>
          <WidgetSmImage src={pakistan} alt="avatar" />
          <WidgetSmUser>
            <WidgetSmUsername>Amina Yasmina</WidgetSmUsername>
            <WidgetSmUserTitle>Manager</WidgetSmUserTitle>
          </WidgetSmUser>
          <WidgetSmButton>
            <Visibility className="widgetSmIcon"/>
            Display
          </WidgetSmButton>
        </WidgetSmListItem>
      </WidgetSmList>
    </WidgetSmContainer>
  )
}
