import React from 'react';
import './featuredinfo.css';

import {
    Featured, 
    FeaturedItem,
    FeaturedTitle,
    FeaturedMoneyContainer,
    FeaturedMoney,
    FeaturedMoneyRate,
    FeaturedSub,
} from './FeaturedInfoStyles';

import { ArrowDownward, ArrowUpward } from '@material-ui/icons';

export default function FeaturedInfo() {
  return (
    <Featured id='featuredinfo'>
        <FeaturedItem>
            <FeaturedTitle>Revenue</FeaturedTitle>
            <FeaturedMoneyContainer>
                <FeaturedMoney>£2,415</FeaturedMoney>
                <FeaturedMoneyRate>-11.4 <ArrowDownward className="featuredIcon negative"/></FeaturedMoneyRate>
            </FeaturedMoneyContainer>
            <FeaturedSub>Compare to last month</FeaturedSub>
        </FeaturedItem>

        <FeaturedItem>
            <FeaturedTitle>Sales</FeaturedTitle>
            <FeaturedMoneyContainer>
                <FeaturedMoney>£4,415</FeaturedMoney>
                <FeaturedMoneyRate>-1.4 <ArrowDownward className="featuredIcon negative"/></FeaturedMoneyRate>
            </FeaturedMoneyContainer>
            <FeaturedSub>Compare to last month</FeaturedSub>
        </FeaturedItem>

        <FeaturedItem>
            <FeaturedTitle>Cost</FeaturedTitle>
            <FeaturedMoneyContainer>
                <FeaturedMoney>£2,225</FeaturedMoney>
                <FeaturedMoneyRate>+2.4 <ArrowUpward className="featuredIcon"/></FeaturedMoneyRate>
            </FeaturedMoneyContainer>
            <FeaturedSub>Compare to last month</FeaturedSub>
        </FeaturedItem>
    </Featured>
  )
}
