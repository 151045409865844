import React from 'react';
import './widgetlg.css';
import pakistan from '../topbar/pictures/male_pakistan_200.png';
import { 
  WidgetLgContainer,
  WidgetLgTitle,
  WidgetLgImage,
  WidgetLgTable, 
  WidgetLgTh,
  WidgetLgUser,
  WidgetLgDate,
  WidgetLgAmount,
} from './WidgetLgStyles';

export default function WidgetLg() {
  const Button = ({type}) =>{
    return <button className={'WidgetLgButton ' + type}>{type}</button>
  }
  return (
    <WidgetLgContainer id='widgetlg'>
      <WidgetLgTitle>Latest Transactions</WidgetLgTitle>
      <WidgetLgTable>
        <thead>
          <tr className="WidgetLgTr">
            <WidgetLgTh>Customer</WidgetLgTh>
            <WidgetLgTh>Date</WidgetLgTh>
            <WidgetLgTh>Amount</WidgetLgTh>
            <WidgetLgTh>Status</WidgetLgTh>
          </tr> 
        </thead>
        <tbody>
          <tr className="WidgetLgTr">
            <WidgetLgUser>
              <WidgetLgImage src={pakistan} alt="avatar" />
              <span className="WidgetLgName">Kalifa Mourad</span>
            </WidgetLgUser>
            <WidgetLgDate>12 Jul 2022</WidgetLgDate>
            <WidgetLgAmount>£259.00</WidgetLgAmount>
            <td className="WidgetLgStatus"><Button type="Approved"/></td>
          </tr>

          <tr className="WidgetLgTr">
            <WidgetLgUser>
              <WidgetLgImage src={pakistan} alt="avatar" />
              <span className="WidgetLgName">Kalifa Mourad</span>
            </WidgetLgUser>
            <WidgetLgDate>12 Jul 2022</WidgetLgDate>
            <WidgetLgAmount>£259.00</WidgetLgAmount>
            <td className="WidgetLgStatus"><Button type="Declined"/></td>
          </tr>

          <tr className="WidgetLgTr">
            <WidgetLgUser>
              <WidgetLgImage src={pakistan} alt="avatar" />
              <span className="WidgetLgName">Kalifa Mourad</span>
            </WidgetLgUser>
            <WidgetLgDate>12 Jul 2022</WidgetLgDate>
            <WidgetLgAmount>£259.00</WidgetLgAmount>
            <td className="WidgetLgStatus"><Button type="Pending"/></td>
          </tr>

          <tr className="WidgetLgTr">
            <WidgetLgUser>
              <WidgetLgImage src={pakistan} alt="avatar" />
              <span className="WidgetLgName">Kalifa Mourad</span>
            </WidgetLgUser>
            <WidgetLgDate>12 Jul 2022</WidgetLgDate>
            <WidgetLgAmount>£259.00</WidgetLgAmount>
            <td className="WidgetLgStatus"><Button type="Approved"/></td>
          </tr>
        </tbody>
      </WidgetLgTable>
    </WidgetLgContainer>
  )
}