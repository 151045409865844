import React from 'react';
import './chart.css';
import { LineChart, Line, XAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

import { 
    ChartContainer,
    ChartTitle,
} from './ChartStyles';

export default function Chart({title, data, dataKey, grid}) {
  return (
    <ChartContainer id='chart'>
        <ChartTitle>{title}</ChartTitle>
        <ResponsiveContainer width="100%" height="140" aspect={4 / 1}>
            <LineChart data={data}>
              <XAxis dataKey="name" stroke='#5550bd' />
              <Line type="monotone" dataKey={dataKey} stroke='#5550bd' />
              <Tooltip />
              {grid &&<CartesianGrid stroke='#e0dfdf' strokeDasharray="5 5" />}
              <Legend />
            </LineChart>
        </ResponsiveContainer>
    </ChartContainer>
  )
}
