import React from 'react';
import { DataGrid } from '@material-ui/data-grid';
import './userlist.css';
import './pouchuserlist';
//import pakistan from '../../components/topbar/pictures/female_pakistan_200.png';
import {UserListContainer} from './UserListStyles';
import {DeleteOutline} from "@material-ui/icons";
import {userRows} from '../../dummyData';

export default function UserList() {
    const columns = [
        { field: 'id', headerName: 'ID', width: 90 },
        {
          field: 'user',
          headerName: 'User',
          width: 200,
          renderCell: (params)=>{
            return(
                <div className='userListUser'>
                    <img className='userListImg' src={params.row.avatar} alt=""/>
                    {params.row.username}
                </div>
            )
          }
          //editable: true,
        },
        {
          field: 'email',
          headerName: 'Email',
          width: 200,
          //editable: true,
        },
        {
          field: 'status',
          headerName: 'Status',
          width: 120,
          //editable: true,
        },
        {
            field: 'transaction',
            headerName: 'Transaction Volume',
            width: 160,
            //editable: true,
        },
        {
            field: "action",
            headerName: "Action",
            width: 150,
            renderCell: (params) =>{
                return(
                    <>
                        <button className='userListEdit' id="useredit">Edit</button>
                        <DeleteOutline className='userListDelete' />
                    </>
                )
            }
        },
    ];
      
    
  return (
    <UserListContainer>
        <DataGrid id="datagrid" rows={userRows} columns={columns} pageSize={8} rowsPerPageOptions={[8]} checkboxSelection disableSelectionOnClick />
    </UserListContainer>
  )
}
