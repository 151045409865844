import React from 'react';
import image from './circle-heart.png';
//import image avatar template
import pakistan from './pictures/female_pakistan_200.png';
import { NotificationsNone, Language, Settings } from '@material-ui/icons';

import { 
    TopBar, 
    TopBarWrapper,
    ImageLogo, 
    TopBarIconContainer,
    TopIconBadge,
    TopRight, 
    TopAvatar,  
} from './TopbarStyles';

export default function Topbar() {
  return (
    <TopBar>
        <TopBarWrapper>
            <div className="topLeft">
                <ImageLogo src={image} alt="logo" />
            </div>
            <TopRight>
                <TopBarIconContainer>
                    <NotificationsNone />
                    <TopIconBadge>2</TopIconBadge>
                </TopBarIconContainer>

                <TopBarIconContainer>
                    <Language />
                    <TopIconBadge>2</TopIconBadge>
                </TopBarIconContainer>

                <TopBarIconContainer>
                    <Settings />
                </TopBarIconContainer>
                <TopAvatar src={pakistan} alt="avatar" />
            </TopRight>
        </TopBarWrapper>
    </TopBar>
  )
}
