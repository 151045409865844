/**Dummy data */
export const userData = [
    {
      name: 'Jan',
      "Active User": 4000,
      
    },
    {
      name: 'Feb',
      "Active User": 3000,
      
    },
    {
      name: 'Mar',
      "Active User": 2000,
      
    },
    {
      name: 'Apr',
      "Active User": 2780,
      
    },
    {
      name: 'May',
      "Active User": 1890,
      
    },
    {
      name: 'Jun',
      "Active User": 2390,
      
    },
    {
      name: 'Jul',
      "Active User": 3590,
      
    },
    {
        name: 'Agu',
        "Active User": 1990,
        
    },
    {
        name: 'Sep',
        "Active User": 2690,
        
    },
    {
        name: 'Oct',
        "Active User": 3190,
        
    },
    {
        name: 'Nov',
        "Active User": 1390,
        
    },
    {
        name: 'Dec',
        "Active User": 3490,
        
    },
];

export const userRows = [
  { 
      id: 1, username: 'Snow', 
      avatar: require('./components/topbar/pictures/male_pakistan_200.png'), 
      email:'jon@gmail.com', 
      status:'active', 
      transaction:'£120.00',
  },
  { 
      id: 2, username: 'Snow', 
      avatar: require('./components/topbar/pictures/male_pakistan_200.png'), 
      email:'jon@gmail.com', 
      status:'active', 
      transaction:'£120.00',
  },
  { 
      id: 3, username: 'Snow', 
      avatar: require('./components/topbar/pictures/male_pakistan_200.png'), 
      email:'jon@gmail.com', 
      status:'active', 
      transaction:'£120.00',
  },
  { 
      id: 4, username: 'Snow', 
      avatar: require('./components/topbar/pictures/male_pakistan_200.png'), 
      email:'jon@gmail.com', 
      status:'active', 
      transaction:'£120.00',
  },
  { 
      id: 5, username: 'Snow', 
      avatar: require('./components/topbar/pictures/male_pakistan_200.png'), 
      email:'jon@gmail.com', 
      status:'active', 
      transaction:'£120.00',
  },
  { 
      id: 6, username: 'Snow', 
      avatar: require('./components/topbar/pictures/male_pakistan_200.png'), 
      email:'jon@gmail.com', 
      status:'active', 
      transaction:'£120.00',
  },
  { 
      id: 7, username: 'Snow', 
      avatar: require('./components/topbar/pictures/male_pakistan_200.png'), 
      email:'jon@gmail.com', 
      status:'active', 
      transaction:'£120.00',
  },
  { 
      id: 8, username: 'Snow', 
      avatar: require('./components/topbar/pictures/male_pakistan_200.png'), 
      email:'jon@gmail.com', 
      status:'active', 
      transaction:'£120.00',
  },
  { 
      id: 9, username: 'Snow', 
      avatar: require('./components/topbar/pictures/male_pakistan_200.png'), 
      email:'jon@gmail.com', 
      status:'active', 
      transaction:'£120.00',
  },
  { 
      id: 10, username: 'Snow', 
      avatar: require('./components/topbar/pictures/male_pakistan_200.png'), 
      email:'jon@gmail.com', 
      status:'active', 
      transaction:'£120.00',
  },
];