import styled from "styled-components";

export const ChartContainer = styled.div`
    margin:20px;
    padding:20px;
    -webkit-box-shadow: 5px 5px 15px 5px #000000; 
    box-shadow: 5px 5px 15px 5px #000000;
`;

export const ChartTitle = styled.h3`
    margin-bottom:20px;
`;