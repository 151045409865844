import styled from 'styled-components';

export const TopBar = styled.div`
    width:100%;
    height:50px;
    background-color:white;
    position:sticky;
    top:0;
    z-index:999;
`;

export const TopBarWrapper = styled.div`
    height:100%;
    padding:0px 20px;
    display:flex;
    align-items:center;
    justify-content:space-between;
`;

export const ImageLogo = styled.img`
    max-height:10vh;
    max-width:10vh;
    cursor:pointer;
`;

export const TopAvatar = styled.img`
    width:40px;
    height:40px;
    border-radius:50%;
    cursor:pointer;
`;

export const TopRight = styled.div`
    display:flex;
    align-items:center;
`;

export const TopBarIconContainer = styled.div`
    position:relative;
    cursor:pointer;
    margin-right:10px;
    color:#555;
`;

export const TopIconBadge = styled.span`
    width:15px;
    height:15px;
    position:absolute;
    top:-5px;
    right:0px;
    background-color:red;
    color:white;
    border-radius:50%;
    display:flex;
    align-items:center;
    justify-content:center;
    font-size:10px;
`;