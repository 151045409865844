import React from 'react';
import './sidebar.css';

import { 
    LineStyle, 
    Timeline, 
    TrendingUp, 
    //PersonOutline, 
    StorefrontOutlined,  
} from '@material-ui/icons';

import {FaRegUser} from 'react-icons/fa'
import {BiPound, BiBarChart, BiMessage} from 'react-icons/bi';
import {AiOutlineMail} from 'react-icons/ai';
import {MdOutlineDynamicFeed, MdManageSearch, MdTableChart} from 'react-icons/md';


import { 
    SideBar, 
    SideBarWrapper, 
    SideBarMenu, 
    SideBarTitle, 
    SideBarList, 
    SideBarListItem, 
} from './SidebarStyles';

export default function Sidebar() {
  return (
    <SideBar>
        <SideBarWrapper>
            <SideBarMenu>
                <SideBarTitle>Dashbord</SideBarTitle>
                <SideBarList>
                    <SideBarListItem className='sidebarlisthome active'>
                        <LineStyle className='sidebarIcon'/>
                        Home
                    </SideBarListItem >

                    <SideBarListItem>
                        <Timeline className='sidebarIcon'/>
                        Analytics
                    </SideBarListItem>

                    <SideBarListItem>
                        <TrendingUp className='sidebarIcon'/>
                        Sales
                    </SideBarListItem>
                </SideBarList>
            </SideBarMenu>

            <SideBarMenu>
                <SideBarTitle>Quick Menu</SideBarTitle>
                <SideBarList>
                    <SideBarListItem>
                        <FaRegUser className='sidebarIcon'/>
                        Users
                    </SideBarListItem >

                    <SideBarListItem>
                        <StorefrontOutlined className='sidebarIcon'/>
                        Products
                    </SideBarListItem>

                    <SideBarListItem>
                        <BiPound className='sidebarIcon'/>
                        Transactions
                    </SideBarListItem>

                    <SideBarListItem>
                        <BiBarChart className='sidebarIcon'/>
                        Reports
                    </SideBarListItem>
                </SideBarList>
            </SideBarMenu>

            <SideBarMenu>
                <SideBarTitle>Notifications</SideBarTitle>
                <SideBarList>
                    <SideBarListItem>
                        <AiOutlineMail className='sidebarIcon'/>
                        Mail
                    </SideBarListItem >

                    <SideBarListItem>
                        <MdOutlineDynamicFeed className='sidebarIcon'/>
                        Feedback
                    </SideBarListItem>

                    <SideBarListItem>
                        <BiMessage className='sidebarIcon'/>
                        Messages
                    </SideBarListItem>
                </SideBarList>
            </SideBarMenu>

            <SideBarMenu>
                <SideBarTitle>Staff</SideBarTitle>
                <SideBarList>
                    <SideBarListItem>
                        <MdManageSearch className='sidebarIcon'/>
                        Manage
                    </SideBarListItem >

                    <SideBarListItem>
                        <Timeline className='sidebarIcon'/>
                        Analytics
                    </SideBarListItem>

                    <SideBarListItem>
                        <MdTableChart className='sidebarIcon'/>
                        Reports
                    </SideBarListItem>
                </SideBarList>
            </SideBarMenu>
        </SideBarWrapper>
    </SideBar>
  )
}
